.groupItemWrapper {
    flex: 33%;
    min-height: 300px;
    border: 1px solid #000;
    border-radius: 18px;
    padding: 35px;
    text-align: left;
    display: flex;
    flex-direction: column;
    position: relative;
}

.groupItemWrapper + .groupItemWrapper {
    margin-left: 20px;
}

.groupItemWrapper > .name {
    font-size: 20px;
    font-weight: 900;
    height: 70px;
}

.groupItemWrapper > .details {
    margin-top: 40px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
}

.groupItemWrapper > .details > .name {
    font-weight: 600;
}

.groupItemWrapper > .details > .info {

}

.groupItemWrapper > .moreInfo {
    font-style: italic;
    max-width: 50%;
    align-self: flex-end;
    justify-self: flex-end;
    text-align: right;
    font-size: 14px;
    margin-bottom: 50px;
}

.groupItemWrapper > .button {
    background: #000;
    color: #fff;
    height: 50px;
    border-radius: 14px;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    margin-bottom: 10px;
    margin-top: auto;
    transition: 0.4s;
    font-size: 18px;
    font-weight: 700;
    text-decoration: inherit;
}

.groupItemWrapper > .button:hover {
    transform: scale(1.05);
    color: #000;
    background: #fff;
    cursor: pointer;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .groupItemWrapper + .groupItemWrapper {
        margin-left: 0;
        margin-top: 20px;
    }
}