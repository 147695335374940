.informationItemWrapper {
    min-height: 100px;
    border-radius: 18px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    flex: 25%;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 30px 40px;
    text-align: left;
}

.informationItemWrapper + .informationItemWrapper {
    margin-left: 10px;
}

.informationItemWrapper > .header {
    font-weight: 700;
    position: relative;
}

.informationItemWrapper > .header::before {
    content: '•';
    top: 0;
    left: -15px;
    position: absolute;
}

.informationItemWrapper > .info {
    font-weight: 400;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .informationItemWrapper + .informationItemWrapper {
        margin-left: 0;
        margin-top: 20px;
    }
}