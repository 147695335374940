.legalWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 100px;
}

.legalWrapper > h2 {
    margin-bottom: 30px;
}

.legalWrapper > .infoWrapper {
    width: 100%;
    min-height: 200px;
    border-radius: 18px;
    background-color: #16295B;
    margin-top: 30px;
    padding: 50px;
    padding-right: 150px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.legalWrapper > .infoWrapper > .column > .warn {
    color: #fff;
    padding: 10px 20px;
    background: #361111;
    border-radius: 18px;
    text-align: left;
    font-size: 14px;
    width: 100%;
    margin-top: 20px;
}

.legalWrapper > .infoWrapper > .column > .header {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 35px;
}

.legalWrapper > .infoWrapper > .column > .item {
    display: flex;
    margin-top: 15px;
    font-size: 16px;
    text-align: left;
}

.legalWrapper > .infoWrapper > .column > .item > .name {
    font-weight: 600;
    width: 120px;
    margin-right: 60px;
}

.legalWrapper > .infoWrapper > .column > .item > .info {
    font-weight: 400;
    width: 430px;
}

.legalWrapper > .infoWrapper > .column > .item > .info > a {
    color: inherit;
    text-decoration: inherit;
}

.legalWrapper > .infoWrapper > .column .desc {
    font-weight: 500;
    width: 430px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .legalWrapper > .infoWrapper {
        flex-direction: column;
    }

    .legalWrapper > .infoWrapper > .column > .item {
        flex-direction: column;
        width: 100%;
    }

    .legalWrapper > .infoWrapper > .column > .item > .name {
        font-size: 16px;
        max-width: 100%;
    }

    .legalWrapper > .infoWrapper > .column > .item > .info {
        font-size: 14px;
        max-width: 290px;
    }

    .legalWrapper > .infoWrapper > .column > .warn {
        padding: 10px;
        width: 90%;
        font-size: 12px;
        margin-bottom: 30px;
    }
}