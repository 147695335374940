.headerWrapper {
    width: 100%;
    height: 40px;

    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerWrapper > span {
    font-family: 'Montserrat', sans-serif;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .headerWrapper > span {
        font-family: 'Montserrat', sans-serif;
        text-align: right;
        font-size: 12px;
    }
}