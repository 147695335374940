.courseItemWrapper {
    flex: 36%;
    border-radius: 18px;
    height: 250px;
    background: red;
    padding: 50px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    margin-top: 20px;
}

.courseItemWrapper:nth-child(2n) {
    margin-left: 20px;
}

.courseItemWrapper > .index {
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    width: 38px;
    height: 38px;
    border-radius: 100%;
}

.courseItemWrapper > span {
    font-size: 36px;
    font-weight: 600;
    text-align: left;
    max-width: 80%;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .courseItemWrapper:nth-child(2n) {
        margin-left: 0px;
        margin-top: 20px;
    }
}