.regWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 100px;
}

.regWrapper > h2 {
    margin-bottom: 80px;
}

.regWrapper > form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.regWrapper > form > .fields {
    display: flex;
    justify-content: space-between;
}

.regWrapper > form .column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.regWrapper > form .column + .column {
    margin-left: 30px;
}

.regWrapper > form .column > .lesson {
    font-size: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    margin-bottom: 20px;
}

.regWrapper > form .column > .lesson > input[type='radio'] {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    width: 60px;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
}

.regWrapper > form .column > .lesson > .name {
    width: 150px;
    margin-right: 60px;
    font-size: 16px;
}

.regWrapper > form .column > .lesson > div {
    width: 60px;
    font-size: 13px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.regWrapper > form .column > .lesson > div + div {
    margin-left: 60px;
}

.regWrapper > form .column > .lesson > div > span {
    padding: 0;
    margin: 0;
}

.regWrapper > form .column > .lesson > input[type='radio'] {
    margin: 0;
}

.regWrapper > form .column > .lesson > input[type='radio'] + input[type='radio'] {
    margin-left: 60px;
}

.regWrapper > form .column > .lesson > input[type='radio']:disabled {
    background: rgba(255, 0, 0, 0.08);
    border: 1px solid rgba(255, 0, 0, 0.2);
}

.regWrapper > form .column > .lesson > input[type='radio']:checked {
    border: 1px solid #16295B;
    background: rgba(22, 41, 91, 0.1);
}

.regWrapper > form .column > .lesson > input[type='radio']:checked::before {
    content: '';
    width: 10px;
    height: 10px;
    background: #16295B;
    border-radius: 18px;
    position: absolute;
}

.regWrapper > form .column > .subName {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
}

.regWrapper > form .column > .input {
    border-radius: 18px;
    width: 500px;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 18px 30px;
    margin-bottom: 20px;
    transition: 0.3s;
}

.regWrapper > form .column > .input:focus {
    border-color: #16295B;
}

.formError {
    color: red;
    font-size: 14px;
}

.regWrapper > form > .result {
    width: 100%;
    min-height: 200px;
    background: #16295B;
    border-radius: 18px;
    margin-top: 70px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 60px;
    text-align: left;
}

.regWrapper > form > .result > .item {
    display: flex;
    margin-bottom: 30px;
}

.regWrapper > form > .result > .item > .name {
    font-size: 24px;
    font-weight: 700;
    width: 300px;
}

.regWrapper > form > .result > .item > .info {
    font-size: 24px;
}

.regWrapper > form > .result > .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
}

.regWrapper > form > .result > .footer > span {
    width: 500px;
    color: rgba(255, 255, 255, 0.5)
}

.regWrapper > form > .result > .footer > button {
    border-radius: 14px;
    background: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    width: 260px;
    height: 60px;
    transition: 0.4s;
}

.regWrapper > form > .result > .footer > button:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.modalWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    width: 600px;
    min-height: 200px;
    background: #fff;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    position: relative;
}

.modal > .name {
    font-size: 22px;
    font-weight: 600;
}

.modal > .warn {
    margin-top: 20px;
    color: darkgreen;
    padding: 15px 25px;
    background: rgba(6, 93, 6, 0.2);
    border-radius: 18px;
    border: 2px dotted rgba(6, 93, 6, 0.2);
    text-align: left;
}

.modal > .closeButton {
    position: absolute;
    top: 15px;
    right: 20px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
}

.modal > .closeButton:hover {
    opacity: 0.6;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .regWrapper > form > .fields {
        flex-direction: column;
    }

    .regWrapper > form .column > .input {
        width: 100%;
    }

    .regWrapper > form .column > .lesson > input[type='radio'] {
        width: 30px;
        height: 30px;
    }

    .regWrapper > form .column > .lesson > .name {
        font-size: 12px;
    }

    .regWrapper > form .column > .lesson > div + div {
        margin-left: 30px;
    }

    .regWrapper > form .column > .lesson > input[type='radio'] + input[type='radio'] {
        margin-left: 30px;
    }

    .regWrapper > form .column + .column {
        margin-left: 0;
        margin-top: 20px;
    }

    .regWrapper > form .column > .lesson > .name {
        width: 75px;
    }

    .regWrapper > form .column .lesson > div {
        width: 30px;
    }

    .regWrapper > form .column .lesson > div + div {
        font-size: 12px;
        margin-left: 30px;
    }

    .regWrapper > form > .result > .item {
        flex-direction: column;
    }

    .regWrapper > form > .result > .footer {
        flex-direction: column;
    }

    .regWrapper > form > .result > .footer > span {
        max-width: 290px;
        margin-bottom: 30px;
    }

    .regWrapper > form > .result > span {
        margin-top: 20px;
        max-width: 290px;
    }
}

.warn {
    color: #fff;
    padding: 10px 20px;
    background: #361111;
    border-radius: 18px;
    text-align: left;
    font-size: 14px;
    width: 100%;
    margin-bottom: 50px;
}