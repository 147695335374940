.groupsWrapper {
    width: 100%;
    display: flex;
    margin-top: 35px;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .groupsWrapper {
        flex-direction: column;
    }
}