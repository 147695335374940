.coursesWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 70px;
    box-sizing: border-box;
}

.coursesWrapper > .items {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
}

.coursesWrapper > h2 {

}

@media (min-width: 300px) and (max-width: 1279px) {
    .coursesWrapper > .items {
        flex-direction: column;
    }
}