.benefitItemWrapper {
    flex: 30%;
    border-radius: 18px;
    height: 250px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    text-align: left;
}

.benefitItemWrapper > .name {
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    max-width: 70%;
}

.benefitItemWrapper > .text {
    font-size: 16px;
    font-weight: 400;
    max-width: 80%;
    margin-top: 10px;
}
