.headerBannerWrapper {
    margin-top: 15px;
    width: 100%;
    min-height: 300px;
    background: #16295B;
    border-radius: 18px;
    color: #fff;
    padding: 50px;

    display: flex;
}

.headerBannerWrapper > .content {
    width: 70%;
    text-align: left;
}

.headerBannerWrapper > .content h1, .headerBannerWrapper > .content h3 {
    max-width: 100%;
}

.headerBannerWrapper > img {
    width: 30%;
}

.headerBannerWrapper > .content > .regButton {
    background: #fff;
    color: #000;
    border: 0;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    width: 265px;
    height: 60px;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
    margin-top: 45px;
    text-decoration: inherit;
    transition: 0.4s;
}

.headerBannerWrapper > .content > .regButton:hover {
    transform: scale(1.05);
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .headerBannerWrapper {
        padding: 25px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .headerBannerWrapper > .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .headerBannerWrapper > .content > .regButton {
        width: 240px;
        height: 45px;
    }

    .headerBannerWrapper > img {
        margin-top: 30px;
    }
}