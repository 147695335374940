.defaultInformationBoardWrapper {
    width: 100%;
    min-height: 100px;
    display: flex;
    margin-top: 35px;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .defaultInformationBoardWrapper {
        flex-direction: column;
    }
}