.benefitsWrapper {
    margin-top: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.benefitsWrapper > .items {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .benefitsWrapper > .items {
        flex-direction: column;
        padding-left: 20px;
    }
}