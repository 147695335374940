.telegramWindow {
    width: 300px;
    min-height: 100px;
    border: 1px solid #000;
    position: fixed;
    right: 20px;
    bottom: 20px;
    padding: 35px;
    background: #fff;
    border-radius: 18px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 52px -25px #16295B;
}

.telegramButton {
    text-decoration: none;
    color: #fff;
    background: #16295B;
    padding: 10px 20px;
    border-radius: 18px;
    margin-top: 10px;
    border: 1px solid #16295B;
    transition: 0.3s;
}

.telegramButton:hover {
    background: #fff;
    color: #000;
    border: 1px solid #16295B;
}

.close {
    position: absolute;
    right: 10px;
    top: 5px;
    font-weight: 600;
    cursor: pointer;
    padding: 10px;
}

.close:hover {
    color: red;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .telegramWindow {
        left: 20px;
    }
}