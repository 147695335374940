.formatWrapper {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.formatWrapper > .generalInfo {
    width: 100%;
    min-height: 200px;
    border: 1px solid #000;
    border-radius: 18px;
    margin-top: 60px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.formatWrapper > .generalInfo > .name {
    font-size: 20px;
    font-weight: 900;
}

.formatWrapper > .generalInfo > .detailsWrapper {
    display: flex;
}

.formatWrapper > .generalInfo > .detailsWrapper > .details {
    width: 300px;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 40px;
    font-size: 16px;
}

.formatWrapper > .generalInfo > .detailsWrapper > .details > .name {
    font-weight: 600;
    position: relative;
}

.formatWrapper > .generalInfo > .detailsWrapper > .details > .name:before {
    content: '•';
    top: 0;
    left: -15px;
    position: absolute;
}

.formatWrapper > .generalInfo > .detailsWrapper > .details > .items {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .formatWrapper > .generalInfo > .detailsWrapper {
        flex-direction: column;
    }
}