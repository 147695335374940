.footerWrapper {
    width: 100%;
    background: #242424;
    margin-top: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 40px;
}

.footerWrapper > .column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.footerWrapper > .column > .linksWrapper {
    display: flex;
    height: 150px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #fff;
    padding: 0 80px;
}

.footerWrapper > .column > .linksWrapper > .name {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
}

.footerWrapper > .column > .linksWrapper > .link {
    color: inherit;
    text-decoration: inherit;
    font-size: 16px;
}

.footerWrapper > .column > .smWrapper {
    display: flex;
    height: 150px;
    align-items: flex-start;
}

.footerWrapper > .column > .smWrapper > a + a {
    margin-left: 10px;
}

.footerWrapper > .column > .smWrapper > a {
    transition: 0.3s;
}

.footerWrapper > .column > .smWrapper > a:hover {
    opacity: 0.7;
}

@media (min-width: 300px) and (max-width: 1279px) {
    .footerWrapper {
        flex-direction: column;
    }

    .footerWrapper > .column {
        flex-direction: column;
    }

    .footerWrapper > .column > .linksWrapper {
        height: auto;
        width: 100%;
        align-items: flex-start;
        margin-top: 30px;
    }

    .footerWrapper > .column > .smWrapper {
        margin-top: 30px;
    }
}